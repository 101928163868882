import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";

import { BlogQuery } from "@Models/blog";

interface BlogQueryRaw {
  content: { raw: string };
  createdAt: string;
  date: string;
  excerpt: { raw: string };
  slug: string;
  thumbnail: {
    description: string;
    gatsbyImageData: IGatsbyImageData;
  };
  title: string;
}

const useBlogQuery = (): BlogQuery[] => {
  const query = useStaticQuery(graphql`
    query BlogQuery {
      allContentfulBlog(sort: { fields: date, order: DESC }) {
        nodes {
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData
              }
            }
          }
          date
          excerpt {
            raw
          }
          slug
          thumbnail {
            description
            gatsbyImageData
          }
          title
        }
      }
    }
  `);
  return query.allContentfulBlog.nodes.map((raw: BlogQueryRaw) => {
    return {
      content: raw.content,
      date: new Date(raw.date),
      excerpt: raw.excerpt,
      slug: raw.slug,
      thumbnail: {
        description: raw.thumbnail.description,
        image: raw.thumbnail.gatsbyImageData,
      },
      title: raw.title,
    };
  });
};

export default useBlogQuery;
