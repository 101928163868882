import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";

import { Card, Cell, Grid, Link, Section } from "@Components/blocks";
import { Size } from "@Components/core";
import { Footer, Navigation, Page, Paginator } from "@Components/layout";
import { useBlogQuery } from "@Hooks/blog";
import { useNavigationQuery } from "@Hooks/navigation";
import { useSectionQuery, useSectionTypeParser } from "@Hooks/section";
import { DateFormat, useDateFormat } from "@Hooks/utils";
import { BlogQuery } from "@Models/blog";

interface PreviewProps extends BlogQuery {
  noImage?: boolean;
}

const getBlogPages = () => {
  const blogPages: React.ReactNode[] = [];
  const blogQuery = useBlogQuery();

  for (let i = 0; i < blogQuery.length; i += 5) {
    const spotlightBlog = blogQuery[i];
    const subQuery = blogQuery.slice(i + 1, i + 5);
    const page = (
      <Grid columns={4} columnsSm={1} gap={Size.xs} gapSm={0}>
        <Cell width={2}>
          <Preview {...spotlightBlog} />
        </Cell>
        <Cell width={2} widthSm={1}>
          <Grid columns={2} columnsSm={1} gap={Size.xs} gapSm={0}>
            {subQuery.map((blog, index) => (
              <Cell key={index}>
                <Preview {...blog} noImage />
              </Cell>
            ))}
          </Grid>
        </Cell>
      </Grid>
    );
    blogPages.push(page);
  }

  return blogPages;
};

const Preview = ({ noImage, ...blog }: PreviewProps) => (
  <Link kind="secondary" to={`/blog/${blog.slug}`}>
    <Card
      alt={noImage ? undefined : blog.thumbnail.description}
      image={noImage ? undefined : blog.thumbnail.image}
    >
      <small>{useDateFormat(blog.date, DateFormat.MonthDayYear)}</small>
      <h2>{blog.title}</h2>
      {renderRichText(blog.excerpt)}
    </Card>
  </Link>
);

const BlogPage = () => {
  const blogPages = getBlogPages();
  const navigationQuery = useNavigationQuery();
  const sectionQuery = useSectionQuery("blog");

  return (
    <Page>
      <Navigation {...navigationQuery} />
      <Section align="left" background="white">
        <Grid columns={1}>
          <Cell>
            <h1>Blog</h1>
          </Cell>
          <Cell>
            {!!blogPages.length && (
              <Paginator
                getContent={(page: number) => blogPages[page]}
                totalPages={blogPages.length}
              />
            )}
            {!blogPages.length && (
              <>
                <p>Looks like we don&apos;t have any posts yet!</p>
                <Link kind="primary" title="Go home" to="/" />
              </>
            )}
          </Cell>
        </Grid>
      </Section>
      {sectionQuery?.map((section, index) => {
        const SectionComponent = useSectionTypeParser(section.layout);
        return SectionComponent ? (
          <div id={section.name} key={index}>
            <SectionComponent {...section.body} />
          </div>
        ) : undefined;
      })}
      <Footer background="blue" />
    </Page>
  );
};

export default BlogPage;
