enum DateFormat {
  Date,
  Hour,
  HourMinute,
  MonthDayShort,
  MonthDayYear,
  MonthDayYearShort,
  MonthDayYearNumeric,
}

const getFormat = (format: DateFormat): Intl.DateTimeFormatOptions => {
  switch (format) {
    case DateFormat.Date:
      return {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
    case DateFormat.Hour:
      return { hour12: true, hour: "numeric" };
    case DateFormat.HourMinute:
      return { hour: "numeric", minute: "numeric", hour12: true };
    case DateFormat.MonthDayShort:
      return { month: "short", day: "numeric" };
    case DateFormat.MonthDayYear:
      return { year: "numeric", month: "long", day: "numeric" };
    case DateFormat.MonthDayYearShort:
      return { year: "numeric", month: "short", day: "numeric" };
    case DateFormat.MonthDayYearNumeric:
      return { year: "2-digit", month: "2-digit", day: "2-digit" };
    default:
      throw new Error(
        `Format for type: ${format} was not found. Did you forget to set it up?`
      );
  }
};

const useDateFormat = (date: Date, format: DateFormat) => {
  switch (format) {
    case DateFormat.Hour:
    case DateFormat.HourMinute:
      return date.toLocaleTimeString(undefined, getFormat(format));
    default:
      return date.toLocaleDateString(undefined, getFormat(format));
  }
};

export { DateFormat, useDateFormat };
